<template>
    <img
        v-if="imgSrc"
        class="d-img"
        :src="imgSrc"
        :class="variant"
        :alt="alt"
        :fetchpriority="fetchpriority"
        v-bind="$attrs"
        @error="onImageError"
    >
</template>

<script setup lang="ts">
    import {ref, computed} from "vue";

    interface DImgPropsType {
        src?: string;
        alt: string;
        variant?: "card" | "card-squared" | "fill" | "circle" | "";
        options?: {
            format?: "auto" | "jpg" | "png" | "avif" | "webp";
            height?: string;
            width?: string;
        };
        srcFallback?: string;
        fetchpriority?: string;
    }

    const props = withDefaults(defineProps<DImgPropsType>(), {
        srcFallback: "content-files/image_placeholder",
        fetchpriority: "low",
        loading: "lazy",
    });

    const useFallbackImage = ref(false);

    const imgSrc = computed(() => {
        const src = useFallbackImage.value ? props.srcFallback : props.src;
        if (!src) return undefined;
        if (src.includes("images.ctfassets")) return src;
        const imageOptions = {...{format: "auto"}, ...props.options};

        const queryString = new URLSearchParams(imageOptions).toString();

        return `${src}?${queryString}`;
    });

    const onImageError = () => {
        useFallbackImage.value = true;
    };
</script>

<style scoped lang="scss">
.d-img {
  width: auto;
  max-width: 100%;
}

.card {
  border-radius: var(--radius-20);
}

.card-squared {
  border-radius: var(--radius-20);
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
}

.circle {
  border-radius: 100%;
}

.fill {
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: "center";
}
</style>
