import app from "@songfinch/customer/app.js";
import hash_scroll from "@songfinch/customer/helpers/hash_scroll";
import {generateElementSelector} from "@songfinch/customer/helpers/html_helpers";
import analyticsEvent from "@songfinch/shared/helpers/analyticsEvent";
import modal from "@songfinch/shared/plugins/modal/index";

//This directive can be assigned to root or any element that has stopPropagation in case we want to track links
const ctaHandler = {
    mounted(el, binding) {
        el.addEventListener("click", (e) => clickEventHandler(e, {onlyEvents: false, ...binding.value}));
    }
};

// 2 main logic here:
// 1) Send CTA event
// 2) handle regular links that managed through CMS. if link start with http(s) then do regular redirect with reload
//    Otherwise it will be handler by vue router

export function clickEventHandler (event, {onlyEvents} = {onlyEvents: false}) {
    // ensure we use the link, in case the click has been received by a subelement
    let {target} = event;

    while (target && !["A", "BUTTON"].includes(target.tagName)) target = target.parentNode;

    if (!target) return;

    // Fire tracking event
    let eventElement;
    try {
        eventElement = generateElementSelector(target);
    } catch (e) {
        eventElement = `unknown`;
    }

    const sfCtaAtributes = {};
    //Array.from(target.attributes).forEach((attribute) => {
    for (const attribute of target.attributes) {
        if (attribute.name.indexOf("sfcta-") === 0) {
            sfCtaAtributes[attribute.name.replace("sfcta-", "")] = attribute.value;
        }
    }
    setTimeout(() => {
        analyticsEvent("_sf_cta_clicked", {
            location: eventElement,
            destination: target.href,
            text: target.textContent?.trim(),
            type: target.tagName === "A" ? "link" : "button",
            timestamp: dateTimeInSeconds(),
            ...sfCtaAtributes
        });
    }, 10);

    // handle only links that do not reference external song_page_access
    if (!onlyEvents && target.href && !target.classList.contains("skipVueRouting")) {
        if (!isExternalReplace(target.href) && !target.href.startsWith("mailto:")) {

            if (target?.getAttribute("close-swal") !== null) {
                modal.swal.close();
            }
            // some sanity checks taken from vue-router:
            // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
            const {altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented} = event;
            // don't handle with control keys
            if (metaKey || altKey || ctrlKey || shiftKey) return;
            // don't handle right clicks
            if (button !== undefined && button !== 0) return;
            // don't handle when preventDefault called
            if (defaultPrevented) return;
            // don't handle if `target="_blank"`
            if (target && target.getAttribute) {
                const linkTarget = target.getAttribute("target");
                if (/\b_blank\b/i.test(linkTarget)) return;
            }
            event.preventDefault();
            const url = new URL(target.href);
            if (hash_scroll(url.hash)) return;
            const to = url.pathname + url.search + url.hash;
            if (window.location.pathname !== to && event.preventDefault) {
                return app.config.globalProperties.$router.push(to);
            }
        } else {
            target.setAttribute("target", "_blank");
        }
    }
}

function isExternalReplace(url) {
    const domain = (url) => url.replace("http://", "").replace("https://", "").split("/")[0];
    return domain(location.href) !== domain(url);
}

function dateTimeInSeconds() {
    return Math.round(Date.now() / 1000);
}


export default ctaHandler;
