<script lang="ts" setup>
    import {ref, computed, onMounted, onUnmounted} from "vue";

    import DContainer from "../atoms/DContainer.vue";
    import DLogoLink from "../atoms/DLogoLink.vue";
    import DLink from "../atoms/DLink.vue";
    import DText from "../atoms/DText.vue";
    import DButton from "../atoms/DButton.vue";

    import DSubMenuDropDown from "../molecules/DSubMenuDropDown.vue";
    import DMobileMenuDropDown from "../molecules/DMobileMenuDropDown.vue";

    import type {Navigation, User} from "@songfinch/types";

    import {useModal} from "../composables/useModal";

    import {getAccountNav} from "./SiteNavigation";

    import {useSetNavigationLogo} from "../composables/useSetNavigationLogo";


    const emit = defineEmits(["logout"]);

    const props = withDefaults(
        defineProps<{
            user: User;
            isAdmin?: boolean;
            navigation: Navigation;
            hasTopBanner: boolean;
            cartLength?: number;
            dataTestId?: string;
            removeClearfix: boolean;
            baseTheme: "light" | "dark";
        }>(),
        {
            isAdmin: false,
            dataTestId: "DSiteNavigation",
            removeClearfix: false,
            baseTheme: "light",
            cartLength: 0
        },
    );

    const {logoType} = useSetNavigationLogo();
    const userAuthed = computed(() => !!props?.user);
    const accountNav = computed(() => getAccountNav(props.user));

    const topOfDocument = ref(false);
    const navigationContainer = ref<HTMLElement | null>(null);
    const isScrolledPast = ref(false);

    const {modalActive, openModal, closeModal} = useModal({
        trackingProperties: {
            type: "modal_opened",
        },
    });

    const theme = computed<"white" | "transparent-light" | "transparent-dark">(() => {
        return topOfDocument.value ?  "white" : `transparent-${props.baseTheme}`;
    });


    const handleScroll = () => {
        if (!navigationContainer.value) return;
        const {top} = navigationContainer.value.getBoundingClientRect();
        topOfDocument.value = top <= 0;
        isScrolledPast.value = window.scrollY > 47;
    };

    onMounted(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
    });

    onUnmounted(() => {
        window.removeEventListener("scroll", handleScroll);
    });
</script>

<template>
    <div>
        <div ref="navigationContainer"/>
        <DContainer v-if="!removeClearfix" class="d-site-navigation-clearfix"/>
        <DContainer
            :theme="theme"
            display="flex"
            class="d-site-navigation-wrapper"
            :data-test-id="dataTestId"
            :class="{'scrolled-past': isScrolledPast, 'has-top-banner': hasTopBanner}"
        >
            <DContainer type="nav" class="d-site-navigation">
                <DLogoLink class="d-site-navigation-logo" :theme="theme" :logoType="logoType"/>
                <DContainer type="ul" class="d-site-navigation-list-desktop">
                    <DContainer
                        v-for="item in navigation"
                        :key="item.title"
                        type="li"
                        class="d-site-navigation-list-item"
                    >
                        <template v-if="item.subNavItems">
                            <DSubMenuDropDown :item="item"/>
                        </template>

                        <DLink
                            v-else-if="item.slug"
                            class="d-site-navigation-list-item-link"
                            :class="theme"
                            :to="item.slug"
                        >
                            <DText variant="p-2">
                                {{item.title}}
                            </DText>
                        </DLink>
                        <DLink
                            v-else-if="item.externalLink"
                            class="d-site-navigation-list-item-link"
                            :class="theme"
                            :to="item.externalLink"
                        >
                            <DText variant="p-2">
                                {{item.title}}
                            </DText>
                        </DLink>
                        <template v-else>
                            <DText variant="p-2" class="d-site-navigation-list-item-text" :class="theme">
                                {{item.title}}
                            </DText>
                        </template>
                    </DContainer>
                </DContainer>

                <DSubMenuDropDown
                    v-if="userAuthed"
                    class="d-site-navigation-secondary-list-item-link-desktop"
                    icon="user"
                    :show-title="true"
                    :item="accountNav"
                >
                    <template #logout>
                        <DLink to="#" @click="$emit('logout')">
                            <DText variant="p-4">
                                Log out
                            </DText>
                        </DLink>
                    </template>
                </DSubMenuDropDown>

                <DLink
                    v-else
                    class="d-site-navigation-secondary-list-item-link-desktop"
                    :data-test-id="`${dataTestId}-login`"
                    variant="icon"
                    aria-label="login"
                    icon="user"
                    :to="'/login'"
                />

                <DLink
                    class="d-site-navigation-secondary-list-item-link"
                    :to="'/cart'"
                    variant="icon"
                    icon="cart"
                >
                    <DText v-if="cartLength" variant="l-1">
                        {{cartLength}}
                    </DText>
                </DLink>

                <DContainer class="d-site-navigation-secondary-list-item-link-mobile">
                    <DButton
                        :data-test-id="`${dataTestId}-mobile-menu`"
                        :theme="theme"
                        icon="hamburger"
                        variant="icon"
                        aria-label="menu"
                        @click="openModal()"
                    />
                </DContainer>
            </DContainer>
        </DContainer>

        <DMobileMenuDropDown
            v-if="modalActive"
            :dataTestId="`${dataTestId}-mobile-menu-dropdown`"
            :userAuthed="userAuthed"
            :navigation="navigation"
            :accountNav="accountNav"
            @logout-user="emit('logout')"
            @close="closeModal()"
        />
    </div>
</template>

<style lang="scss" scoped>
.d-site-navigation,
.d-site-navigation-wrapper,
.d-site-navigation-clearfix {
  width: 100%;
  height: 60px;
  padding-block: 16px;
}

.d-site-navigation-wrapper {
  top: 0;
  position: absolute;
  transition: background-color .3s ease;
  backdrop-filter: blur(30px);
  z-index: 10;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.has-top-banner {
    top: 62px;

    @include md {
      top: 47px;
    }

    &.scrolled-past {
      top: 0;
      position: fixed;
    }
  }

.d-site-navigation {
  width: 100%;
  display: grid;
  grid-template-columns: auto 24px 24px;
  column-gap: 30px;
  align-items: center;
  padding-inline: var(--size-30);

  button {
    color: inherit;
  }

  @include lg {
    column-gap: 0.5rem;
    display: flex;
  }

  @include xl {
    max-width: 1440px;
    padding-inline: 0;
    margin: 0 auto;
  }
}

.d-site-navigation-logo {
  height: 30px;
  justify-self: flex-start;

  :deep(svg) {
    height: 30px;
  }

  @include lg {
    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 44px;

    :deep(svg) {
      width: 134px;
      height: auto;
    }
  }
}

.d-site-navigation-list,
.d-site-navigation-list-desktop {
  list-style: none;
  margin: 0;
  padding: 0;
}

.d-site-navigation-list {
  display: flex;
}

.d-site-navigation-list-desktop {
  display: none;

  @include lg {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.d-site-navigation-list-item {
  width: auto;
  position: relative;
  cursor: pointer;
  margin-left: 30px;
  justify-content: center;
  line-height: 1;
}

.d-site-navigation-list-item-link,
.d-site-navigation-list-item-text {
  text-align: center;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    position: absolute;
    top: 2.325rem;
    left: 0;
    background-color: var(--soft-black);
    transition: 0.3s;
  }

  &.transparent-light {
    &:after {
      background-color: var(--soft-black);
    }
  }

  &.transparent-dark {
    &:after {
      background-color: var(--white);
    }
  }

  &:hover {
    &:after {
      height: 4px;
    }
  }
}

.d-site-navigation-secondary-list-item-link {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.d-site-navigation-secondary-list-item-link-desktop,
.d-site-navigation-secondary-list-item-link-mobile,
.d-site-navigation-secondary-list-item-link {
  font-size: 18px;

  @include lg {
    margin-left: 30px;
    line-height: 1;
  }
}

.d-site-navigation-secondary-list-item-link-desktop {
  display: none;

  @include lg {
    display: block;
  }
}

.d-site-navigation-secondary-list-item-link-mobile {
  @include lg {
    display: none;
  }
}
</style>
