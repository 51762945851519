import axios, {type AxiosError} from "axios";
import {computed, readonly, ref} from "vue";
import {useSessionStorage, StorageSerializers} from "@vueuse/core";
import store from "@songfinch/customer/store";
import router from "@songfinch/customer/router";
import get_error from "@songfinch/shared/helpers/get_error";
import {nth, pick} from "lodash-es";
import {loadProductOrDefault} from "@songfinch/customer/composables/useProduct";
import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";

import {getCmsData} from "@songfinch/customer/helpers/instantStepsHelpers";

import useInstantNames, {resetNamesData} from "@songfinch/customer/composables/useInstantNames";

import type {
    IpCollectionCmsData, 
    IpCollectionCmsDataCategory, 
    IpCollectionCmsDataSubCategory
} from "@songfinch/customer/types/instant/IpCmsData";

import type {
    InstantProductAnswersObject,
    InstantSubmissionData, 
    ProductInstantData, 
    PreviewTaskID, 
    PreviewListItem, 
    CustomAxiosRequestConfig
} from "@songfinch/customer/types/instant/InstantBuilder";

import type {InstantProductCollectionData} from "@songfinch/customer/types/instant/IpCollectionsData";

// 
// Composables
// 
const {selectedName, selectedNameKey} = useInstantNames();

// 
// CONFIG
// 
const DEFAULT_INSTANT_PRODUCT = "instant-product";

// 
// STORAGE
// 
const selectedInstantProduct = useSessionStorage<string>(
    "sf_selected_instant_product", 
    DEFAULT_INSTANT_PRODUCT, 
    {writeDefaults: false}
);

// Collection 
const vitamixCollectionId = useSessionStorage<number>(
    "sf_instant_vitamix_collection_id", 
    null, 
    {serializer: StorageSerializers.number}
);

const vitamixCollectionTitle = useSessionStorage<string>(
    "sf_instant_vitamix_collection_title",
    null
);

// Answers
const answersStorage = useSessionStorage<InstantProductAnswersObject>(
    "sf_instant_answers", 
    {}
);

// Previews 
const currentPreviewIndexStorage = useSessionStorage<number>("sf_current_selected_preview", -1);
const previewsListStorage = useSessionStorage<PreviewListItem[]>("sf_instant_previews_list", []);
const previewTaskID = useSessionStorage<PreviewTaskID>("sf_instant_preview_task_id", undefined);


// 
// Refs
// 
const collectionData = ref<InstantProductCollectionData>();

// 
// Methods
// 

export function setSelectedInstantProduct(slug: string) {
    selectedInstantProduct.value = slug;
}

export async function setVitamixCollection(val: string | number) {
    val = parseInt(val as string);
    if (!val || (vitamixCollectionId.value && vitamixCollectionId.value !== val)) {
        resetInstantData();
    }
    vitamixCollectionId.value = val || null;
    await Promise.all([
        setVitamixCollectionData(val),
        loadInstantCollection()
    ]);
    selectedInstantProduct.value = collectionData.value?.product_slug || DEFAULT_INSTANT_PRODUCT;
}



//Title used from CMS that why we saving int separately
const isIpCollectionCmsDataSubCategory = (value: unknown): value is IpCollectionCmsDataCategory => {
    return !!value && value.hasOwnProperty("subcategories");
};

async function setVitamixCollectionData(
    val: number, 
    categories: IpCollectionCmsDataCategory[] | IpCollectionCmsDataSubCategory[] |  undefined = undefined, 
    prefix = ""
) {
    if (!val) {
        vitamixCollectionTitle.value = null;
        return;
    }

    if (!categories) {
        let data = getCmsData<IpCollectionCmsData>("IpCollections");
        if (!data.categories) {
            data = await store.dispatch("cms/getField", {group: "instantBuilder", slug: "IpCollections"});
        }
        categories = data.categories;
    }

    categories?.find(async(item) => {
        if (+item.cat_id === val) {
            vitamixCollectionTitle.value = prefix + item.title;
            return true;
        }
        if (
            isIpCollectionCmsDataSubCategory(item) && 
            await setVitamixCollectionData(val, item.subcategories, `${item.title}: `)
        ){
            return true;
        }
    });
}


export async function loadInstantCollection() {
    const res = await vitamixApiClient.get(`/collections/${vitamixCollectionId.value}`);
    const collectionDataRes = {id: vitamixCollectionId.value, ...res.data} as InstantProductCollectionData;
    collectionData.value = collectionDataRes;
    return collectionDataRes;
}

//########################
//Vitamix Api
export const vitamixApiClient = axios.create({
    baseURL: window.location.origin + "/vitamix/api/v2/",
    headers: {
        "Accept": "application/json",
        "X-CSRF-Token": (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)?.content
    }
});

vitamixApiClient.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        const config = error.config as CustomAxiosRequestConfig;
        if (!config?.skipToast) $toastMsg(get_error(error));
        if (error?.response?.data?.reset_data) {
            resetInstantData();
            router.push({name: "IpCollections"}).then();
        }
        return Promise.reject(error);
    }
);
//########################

//########################
// Answers
export function setAnswer(key: number, value: string) {
    if (answersStorage.value[key]) {
        const index = answersStorage.value[key].indexOf(value);

        if (index === -1) {
            answersStorage.value[key].push(value);
        } else {
            answersStorage.value[key].splice(index, 1);
        }
    } else {
        answersStorage.value[key] = [value];
    }
}

const answersUpdated = ref(false);

export function setAnswersUpdated(val: boolean) {
    answersUpdated.value = val;
}

//########################
// Preview

export function setCurrentSelectedPreview(val: number) {
    currentPreviewIndexStorage.value = val;
}

function throwAndRedirectToStep(message: string, index: number) {
    throw ({error: message, redirectObj: {name: "IpBuilderStep", params: {step: +index + 1}}});
}

async function getAllAnswers(data: InstantProductAnswersObject) {
    const answers = {...data};
    let questions = collectionData.value?.questions;
    
    if (!questions?.length) {
        const collectionDataRes =  await loadInstantCollection();
        if (collectionDataRes.questions) {
            throw new Error("No questions found");
        }
        questions = collectionDataRes.questions;
    }

    questions.forEach((step, index) => {
        // If answer key without value exist, check if we have GENERAL key in asnwers list and max answer allowed ansers 2 or more
        // Then set unasnwered key with GENERAL
        const hasGeneral = step.max_answer_count > 1 && step.answers.find(answer => answer.answer_key === "GENERAL");
        if (answers[step.id].length !== step.max_answer_count) {
            if (!hasGeneral) throwAndRedirectToStep("Missing selection", index);
            answers[step.id].push("GENERAL");
        } else {
            answers[step.id].forEach((answerPicked) => {
                const answerExistInList = step.answers.some((answer) => answer.answer_key === answerPicked);

                if (!answerExistInList) {
                    setAnswer(step.id, answerPicked); // remove not existing answer
                    setAnswersUpdated(true);
                    throwAndRedirectToStep("Unable to find answer", index);
                }
            });
        }
    });

    return answers;
}

type RedirectErrorObjectType = {
    name: string, 
    params: {step: number}
}

type RedirectErrorType = {
    error: string
    redirectObj: RedirectErrorObjectType
}

const isRedirectError = (error: unknown): error is RedirectErrorType => {
    return !!error && !!(error as RedirectErrorType)?.redirectObj;
};

export async function submitPreview(previewIndex?: number) {
    previewIndex ??= currentPreviewIndexStorage.value;
    let answersForSubmission;
    try {
        answersForSubmission = await getAllAnswers(answersStorage.value);
    } catch (e) {
        $toastMsg(get_error(e));
        if (isRedirectError(e)) return router.push(e.redirectObj);
        return router.push({name: "InstantProductBuilder"});
    }
    const data: InstantSubmissionData = {
        answers: answersForSubmission,
        name_key: selectedNameKey.value,
        spelling: selectedName.value?.[0].customSpelling,
        count: 1,
    };

    if (previewIndex === -1) {
        data.exclude_artists = previewsListStorage.value.map(p => p.artist_id);
    } else {
        data.artist_id = previewsListStorage.value[previewIndex].artist_id;
    }
    const res = await vitamixApiClient.post(`/collections/${vitamixCollectionId.value}/previews`, data);
    previewTaskID.value = res.data.task_ids[0];

    return router.push({name: "IpLoadingPreview"});
}

type GetTaskDataParams = {
    taskId?: string,
    skipToast?: boolean
}

export async function getTaskData({taskId}: GetTaskDataParams = {}) {
    taskId ||= previewTaskID.value;
    if (!taskId) return;
    const res = await vitamixApiClient.get(
        `/collections/${vitamixCollectionId.value}/previews?task_ids[]=${taskId}`);
    const data = res.data.previews[`${taskId}`];

    if (data.status === "completed") {
        const answers_data = parseAnswersForKeyVal(answersStorage.value);
        data.data.ip_builder_data  = {
            answers: {...answers_data},
            name_key: selectedNameKey.value,
            spelling: selectedName.value?.[0].customSpelling
        };
        data.data.preview_count = 0;
        if (currentPreviewIndexStorage.value === -1) {
            const nextPreview = previewsListStorage.value.length;
            previewsListStorage.value.push(data.data);
            setCurrentSelectedPreview(nextPreview);
        } else {
            previewsListStorage.value[currentPreviewIndexStorage.value] = data.data;
        }

        data.progress = 100;
        previewTaskID.value = undefined;
    }
    return data;
}

export async function lockSong(previewSong: PreviewListItem) {
    const name = selectedInstantProduct.value;
    const product = await loadProductOrDefault({productSlug: name}, DEFAULT_INSTANT_PRODUCT);
    if (selectedInstantProduct.value !== product.name) selectedInstantProduct.value = product.name;

    let answers;
    let task_id;
    try {
        answers = await getAllAnswers(answersStorage.value);
        task_id = await submitFullSong(previewSong, answers);
    } catch (e) {
        store.commit("shared/warningMsg", get_error(e));
        return router.push({name: "InstantProductBuilder"});
    }

    // Not sure which collection do we need
    product.instant_data = {
        collection_id: vitamixCollectionId.value,
        artist_id: previewSong.artist_id,
        artist_display_name: previewSong.artist,
        task_id: task_id,
        title: previewSong.title,
        lyrics: previewSong.lyrics,
        template_song_id: previewSong.template_song_id,
        answers,
        previews_generated: previewsListStorage.value.map(preview => pick(preview, ["title", "artist"])),
        selected_name: selectedName.value ? pick(selectedName.value[0], ["name_key", "spelling"]) : undefined,
    } as ProductInstantData;

    const cartIndex = store.state.cart.cart.findProductIndexByName(name);
    if (cartIndex !== -1) store.commit("cart/resetCart");

    if (!await store.dispatch("cart/addProduct", {product, skipToast: true, dontShowDetailModal: true})) return false;
    await router.push({name: "IpConfirm"});
}

async function submitFullSong(previewSong: PreviewListItem, answers: InstantProductAnswersObject) {
    const data: InstantSubmissionData = {
        answers,
        template_song_id: previewSong.template_song_id,
        name_key: selectedNameKey.value,
        spelling: selectedName.value?.[0].customSpelling
    };
    const res = await vitamixApiClient.post(`/collections/${vitamixCollectionId.value}/full_songs`, data);
    return res.data.task_id;
}

export function resetInstantData() {
    previewsListStorage.value = [];
    answersStorage.value = {};
    currentPreviewIndexStorage.value = -1;
    lastStepVisited.value = "";
    previewTaskID.value = undefined;
    vitamixCollectionId.value = null;
    vitamixCollectionTitle.value = "";
    selectedInstantProduct.value = DEFAULT_INSTANT_PRODUCT;
    resetNamesData();
}

type NormalizedAnswer = {[k: string]: string}
type NormalizedAnswers = {[k: string]: NormalizedAnswer}

export function parseAnswersForKeyVal(questionsAnswers: InstantProductAnswersObject) {
    // KeyVal store does not allow array data inside a key. Converting answers per question to {index: value} for each question
    // { questionId: { index0: first_answer, index1: second_answer } }
    const result = Object.entries(questionsAnswers).reduce((questionHash: NormalizedAnswers, [key, answers]) => {
        questionHash[key] = (answers as string[]).reduce((answerHash: NormalizedAnswer, answer: string, index: number) => {
            answerHash[index] = answer;
            return answerHash;
        }, {});
        return questionHash;
    }, {});

    return result;
}
//##########################################
// Instant bundler page

const lastStepVisited = useSessionStorage<string>("sf_instant_last_step_v2", "");
export function setLastPageVisited(fullPath: string) {
    lastStepVisited.value = fullPath;
}

//##########################################
const exportData = {
    collectionData: readonly(collectionData),
    answers: readonly(answersStorage),
    vitamixCollectionId: readonly(vitamixCollectionId),
    vitamixCollectionTitle: readonly(vitamixCollectionTitle),
    previewsList: readonly(previewsListStorage),
    currentPreviewIndex: readonly(currentPreviewIndexStorage),
    currentPreview: readonly(computed<PreviewListItem>(() => nth(previewsListStorage.value, currentPreviewIndexStorage.value) as PreviewListItem)),
    answersUpdated: readonly(answersUpdated),
    selectedInstantProduct: readonly(selectedInstantProduct),
    lastStepVisited: readonly(lastStepVisited)
};

export default function useInstantProductBuilder() {
    return exportData;
}
