import {inject, ref} from "vue";

import {scrollLock, scrollUnlock} from "@songfinch/utils/src/dom";

import type {InjectedModalTrackEvent} from "@songfinch/types";

type UseModalTrackingProperties =
  | {
      type: "modal_opened";
      payload?: { [k: string]: string };
    }
  | {
      type: "video_opened";
      payload: { video_title: string };
    };

type UseModalParams = { trackingProperties: UseModalTrackingProperties };

export function useModal({trackingProperties}: UseModalParams) {
    const modalOpenTrackEvent = inject<InjectedModalTrackEvent | null>("modalOpenTrackEvent", null);

    const modalActive = ref(false);

    const openModal = (e?: MouseEvent) => {
        modalActive.value = true;
        scrollLock();

        // Don't do tracking if no event occures
        if (!e) return;

        if (modalOpenTrackEvent) {
            modalOpenTrackEvent(
                e,
                trackingProperties.type,
                trackingProperties.payload || {},
            );
        }
    };

    const closeModal = () => {
        modalActive.value = false;
        scrollUnlock();
    };

    return {
        modalActive,
        openModal,
        closeModal,
    };
}
