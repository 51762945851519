<template>
    <div class="imageUploaderPopup">
        <div class="@container @min-h-screen @flex @justify-center @py-20">
            <div class="@grid @grid-cols-12 @items-center !@m-auto @max-w-1000 @w-full">
                <div class="@col-span-12 @px-4 tablet-lg:@col-span-6 @text-center tablet-lg:@text-left @flex">
                    <div class="contentBox p3">
                        <div v-if="cmsData.content" v-html="cmsData.content"/>
                        <div v-else>
                            <h2 v-html="product.title"/>
                            <p>To achieve the best results possible, we recommend using high resolution photos. We currently support jpg, png, or heic files.</p>
                        </div>
                        <button class="mkButton !@hidden tablet-lg:!@inline-block noLoading !@min-w-[275px] @mt-8 tall" :disabled="isDisabled" @click="addProduct">
                            {{update ? "Update" : "ADD TO CART"}}
                        </button>
                    </div>
                </div>
                <div class="@col-span-12 @px-4 tablet-lg:@col-span-6">
                    <div class="imageBox @mt-10 tablet-lg:@mt-0" :class="{'@inline-block': cmsData.image}">
                        <div class="upload-button-container" :class="{'@mt-auto': (cover_public_id && !error)}">
                            <button class="mkButton short noMinWidth" :class="{beige: cover_public_id}" @click.prevent="openUploader">
                                {{error ? "upload new photo" : cover_public_id ? 'edit' : 'upload a photo'}}
                            </button>
                        </div>
                        <img v-if="cmsData.image" class="productImage" :src="$cld.url(cmsData.image)" alt="product image">
                        <div
                            class="uploadContent"
                            :class="{error}"
                            :style="[coordinates, {backgroundImage: $cld.bgUrl(cover_public_id?.metadata?.url)}]"
                        >
                            <div v-if="error" class="p4 @text-sfc-white @px-4 tablet:@px-10">
                                <img :src="warningIcon" class="@mb-4" alt="error" style="width: 28px;">
                                <div v-html="error"/>
                            </div>
                        </div>
                        <img v-if="cmsData.image" :src="shadow" class="boxShadows @none small:@inline-block" alt="shadow">
                        <img v-if="cmsData.image" :src="shadowMobile" class="boxShadows mob small:@hidden" alt="shadow">
                    </div>
                    <div>
                        <button
                            class="mkButton tablet-lg:!@hidden !@inline-block fullMob noLoading !@min-w-[275px] @my-12 tall"
                            :disabled="isDisabled"
                            @click="addProduct"
                        >
                            {{update ? "Update" : "ADD TO CART"}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import is_image_exists from "@songfinch/customer/helpers/is_image_exists";
    import warningIcon from "@songfinch/customer/assets/images/warning.png";
    import shadow from "@songfinch/customer/assets/images/product_shadow.png";
    import shadowMobile from "@songfinch/customer/assets/images/product_shadow_mob.png";
    import modal from "@songfinch/shared/plugins/modal/index";
    import {find} from "lodash-es";

    import Uppy from "@uppy/core";
    import Dashboard from "@uppy/dashboard";
    import AwsS3 from "@uppy/aws-s3";
    import ImageEditor from "@uppy/image-editor";
    import DropTarget from "@uppy/drop-target";
    import Compressor from "@uppy/compressor";
    import ImageSizeValidator from "@songfinch/customer/helpers/shared/UppyImageSizer.ts";
    import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";

    import "@uppy/core/dist/style.css";
    import "@uppy/dashboard/dist/style.css";
    import "@uppy/image-editor/dist/style.css";

    export default {
        name: "ImageUploaderPopup2",
        props: {
            product: {type: Object, required: true},
        },
        emits: ["submit"],
        data() {
            return {
                count: 0,
                cms: null,
                cover_public_id: "",
                error: "",
                warningIcon,
                shadow,
                shadowMobile,
                inProgress: false,
                upload_preset: "hrlccu7o",
                widget: null,
                isDisabled: true
            };
        },
        computed: {
            update() {
                return this.product.update;
            },
            cmsData() {
                return find(this.cms?.list, el => el.product_name === this.product.name) || {};
            },
            coordinates() {
                return this.cmsData.coordinates || {width: "100%", transform: "none", maxWidth: "100%", paddingTop: "100%", position: "static"};
            }
        },
        watch: {
            async cover_public_id(val) {
                this.isDisabled = true;
                if (val) {
                    try {
                        await is_image_exists(this.$cld.url({"url": val.metadata.url}));
                        this.isDisabled = false;
                    } catch (e) {
                        this.error = "Unable to load image! Please try again or try a different photo.";
                    }
                }
            }
        },
        async created() {
            this.cover_public_id = this.product.cover_public_id;
            this.cms = await this.$store.dispatch("cms/getField", {group: "modals", slug: "productImageUploader"});
        },
        methods: {
            setupUppy() {
                const limits = this.cmsData?.limits;
                this.uppy = new Uppy({
                    restrictions: {
                        minNumberOfFiles: 1,
                        maxNumberOfFiles: 1,
                        maxFileSize: 20971520, // 20mb
                        allowedFileTypes: [".jpeg", ".jpg", ".png"], // TODO - can we support webp and heic?
                    }
                })
                    .use(Dashboard, {
                        showProgressDetails: true,
                        proudlyDisplayPoweredByUppy: false,
                        autoOpen: "imageEditor",
                        closeAfterFinish: true,
                        target: ".swal2-container", // I wasn't sure how to play with z-index so I made this a child of the swal modal
                    })
                    .use(AwsS3, {
                        // TODO - add new endpoint
                        companionUrl: "/", // will call the presign endpoint on `/s3/params`
                    })
                    .use(ImageEditor, {
                        actions: {
                            revert: false,
                            rotate: false,
                            granularRotate: false,
                            flip: false,
                            zoomIn: false,
                            zoomOut: false,
                            cropSquare: false,
                            cropWidescreen: false,
                            cropWidescreenVertical: false,
                        },
                        cropperOptions: {
                            aspectRatio: +this.cmsData?.cropping_ratio || 1,
                            guides: false,
                            viewMode: 1,
                        },
                        target: Dashboard
                    })
                    .use(DropTarget, {
                        target: document.body,
                    })
                    .use(Compressor)
                    .use(ImageSizeValidator, {
                        minWidth: limits?.min_width || 600,
                        minHeight: limits?.min_height || 800,
                        maxWidth: limits?.max_width,
                        maxHeight: limits?.max_height,
                        onError: errMsg => $toastMsg(errMsg)
                    });
                this.uppy.on("upload-success", (file) => {
                    // this.cover_public_id = {
                    //     public_id: file.meta["key"],
                    //     format: file.extension,
                    //     url: `${window.appSettings.filesCDN}${file.meta["key"]}`,
                    //     secure_url: `${window.appSettings.filesCDN}${file.meta["key"]}`,
                    //     original_filename: file.name.split(".")[0],
                    //     resource_type: "image"
                    // };
                    this.cover_public_id = {
                        id: file.meta["key"].match(/^.*\/cache\/(.+)/)[1], // object key without prefix
                        storage: "cache",
                        metadata: {
                            url: `${window.appSettings.filesCDN}${file.meta["key"]}`,
                            size: file.size,
                            filename: file.name,
                            mime_type: file.type,
                        }
                    };
                });
                this.uppy.on("complete", () => {
                    this.inProgress = false;
                    this.uppy.cancelAll();
                    this.uppy.getPlugin("Dashboard").closeModal();
                    this.uppy.getPlugin("Dashboard").setOptions({disabled: false});
                });
                this.uppy.on("file-editor:cancel", () => {
                    this.uppy.cancelAll();
                });
                this.uppy.on("file-editor:complete", async file => {
                    if (await this.uppy.getPlugin("ImageSizeValidator").validateImageSize(file)) {
                        this.uppy.upload();
                    } else {
                        this.uppy.cancelAll();
                    }
                });
                this.uppy.on("upload", () => {
                    this.uppy.getPlugin("Dashboard").setOptions({disabled: true});
                });
            },
            openUploader() {
                // if (this.inProgress) return;
                // this.inProgress = true;
                this.error = "";
                if (!this.uppy) this.setupUppy();
                this.uppy.getPlugin("Dashboard")?.openModal();
            },
            addProduct() {
                this.$emit("submit", {
                    ...this.product,
                    cover_public_id: this.cover_public_id,
                    expire: this.$dayjs().add(7, "day")
                });
                modal.swal.close();
            }
        },
    };
</script>

<style scoped>
    .uppy-Root {
        z-index: 9999 !important;
    }
    .imageUploaderPopup {
        overflow-y: auto;

        .contentBox {
            max-width: 575px;
            margin: auto;
            @screen tablet-v {
                max-width: 375px;
                margin-left: 0;
            }
        }

        .imageBox {
            position: relative;


            .productImage {
                max-width: 100%;
                position: relative;
                z-index: 2;
                pointer-events: none;
            }


            .uploadContent {
                position: absolute;
                background: var(--sfc-dark-beige);
                transform: translate(-50%, -50%);
                background-position: center;
                background-size: cover;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-self: center;

                &.error {
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        background-color: var(--sfc-black);
                    }
                }

                > * {
                    position: relative;
                    z-index: 2;
                }
            }

            .boxShadows {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 45%);
                bottom: 0;
                width: 143.5%;

                &.mob {
                    width: 112.5%;
                }
            }
        }
    }

    .upload-button-container {
        position: absolute;
        top: calc(50% - .5rem);
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
    }
</style>
